import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <defs>
      <linearGradient id="myGradient" gradientTransform="rotate(90)">
        <stop offset="0%" stop-color="#00103d" />
        <stop offset="100%" stop-color="#001652" />
      </linearGradient>
    </defs>
    <path
      id="Tasks"
      fillRule="evenodd"
      fill="url('#myGradient')"
      d="M20,0H44A20,20,0,0,1,64,20V44A20,20,0,0,1,44,64H20A20,20,0,0,1,0,44V20A20,20,0,0,1,20,0ZM48.959,22.1L35.011,44.057A1.992,1.992,0,0,1,33.333,45H32.542a1.992,1.992,0,0,1-1.678-.943L23.642,32.687a1.045,1.045,0,0,1,.725-1.4h2.552A2.052,2.052,0,0,1,28.4,32.531l4.538,7.145L44.2,21.943A2,2,0,0,1,45.882,21H48.4A0.707,0.707,0,0,1,48.959,22.1ZM32.873,34.714l-2.552-3.429,6.223-9.342A2,2,0,0,1,38.227,21h2.516a0.707,0.707,0,0,1,.561,1.1Zm-5.955,9.429A2.71,2.71,0,0,1,24.826,45H24.035a1.993,1.993,0,0,1-1.678-.943L15.135,32.687a1.045,1.045,0,0,1,.725-1.4h2.552a2.052,2.052,0,0,1,1.481,1.246l7.026,10.754v0.857Z"
    />
  </svg>
))
