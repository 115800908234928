import { Lang } from "src/config/i18n"

// TODO: Replace this with a yml file
const locales: Record<string, () => any> = {
  en: () => require("date-fns/locale/en-US").default,
  "en-GB": () => require("date-fns/locale/en-GB").default,
  es: () => require("date-fns/locale/es").default,
}

export default (lang: Lang) =>
  locales[lang] ? locales[lang]() : locales["en"]()
