import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <defs>
      <linearGradient id="myGradient" gradientTransform="rotate(90)">
        <stop offset="0%" stop-color="#00103d" />
        <stop offset="100%" stop-color="#001652" />
      </linearGradient>
    </defs>
    <path
      id="Chat"
      fillRule="evenodd"
      fill="url('#myGradient')"
      d="M20,0H44A20,20,0,0,1,64,20V44A20,20,0,0,1,44,64H20A20,20,0,0,1,0,44V20A20,20,0,0,1,20,0ZM46.222,44.444l0.889,0.889a1.8,1.8,0,0,1,.889.889v0.889a0.82,0.82,0,0,1-.889.889H37.333c-6.479,0-10.667-4.187-10.667-10.666s4.188-10.667,10.667-10.667S48,30.854,48,37.333C48,40.215,47.712,42.636,46.222,44.444ZM37.333,30.222a6.685,6.685,0,0,0-7.111,7.111,6.685,6.685,0,0,0,7.111,7.111,6.685,6.685,0,0,0,7.111-7.111A6.685,6.685,0,0,0,37.333,30.222ZM26.667,19.556a6.685,6.685,0,0,0-7.111,7.111c0,3.044,1.337,5.27,3.556,6.222a1.32,1.32,0,0,1,.889.889v2.667a0.821,0.821,0,0,1-.889.889H16.889A0.82,0.82,0,0,1,16,36.445V35.556a1.794,1.794,0,0,1,.889-0.889l0.889-.889C16.288,31.969,16,29.548,16,26.667,16,20.187,20.187,16,26.667,16c5.211,0,9.408,2.68,10.667,7.111a0.881,0.881,0,0,1-.889.889H34.667a2.466,2.466,0,0,1-1.778-.889A6.63,6.63,0,0,0,26.667,19.556Z"
    />
  </svg>
))
