import { Lang } from "src/config/i18n"

// TODO: Replace this with a yml file
const formats: Record<string, string> = {
  en: "MMMM d, yyyy",
  "en-GB": "MMMM d, yyyy",
  es: "d [de] MMMM [de] yyyy",
}

export default (lang: Lang) => formats[lang] || formats["en"]
