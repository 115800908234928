import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <defs>
      <linearGradient id="myGradient" gradientTransform="rotate(90)">
        <stop offset="0%" stop-color="#00103d" />
        <stop offset="100%" stop-color="#001652" />
      </linearGradient>
    </defs>
    <path
      id="Design"
      fillRule="evenodd"
      fill="url('#myGradient')"
      d="M20,0H44A20,20,0,0,1,64,20V44A20,20,0,0,1,44,64H20A20,20,0,0,1,0,44V20A20,20,0,0,1,20,0ZM46.757,25.152L33.5,39.212a1.727,1.727,0,0,0-.418.844A5.917,5.917,0,0,1,26.927,45H19.791A0.818,0.818,0,0,1,19,44.154V43.762a0.857,0.857,0,0,1,.353-0.7l0.879-.622a0.856,0.856,0,0,0,.353-0.7l0.006-3.467c0-3.444,1.773-5.856,4.637-6.527A1.569,1.569,0,0,0,26.02,31.3L39.287,17.229a0.76,0.76,0,0,1,1.12,0l6.35,6.735A0.877,0.877,0,0,1,46.757,25.152Zm-19.83,9.761c-2.1,0-3.169,1.131-3.169,3.361l0,3.361h3.174c2.1,0,3.169-1.131,3.169-3.361S29.03,34.913,26.927,34.913Zm12.92-13.524L29.734,32.118a5.653,5.653,0,0,1,2.992,3.165L42.835,24.558Z"
    />
  </svg>
))
