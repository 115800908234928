import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props } from "./types"
import { useNewsletterSubscription } from "src/hooks/useNewsletterSubscription"
import { graphql, useStaticQuery } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import Img from "gatsby-image"

const FlowX: FC<Props> = ({ variant }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/Banners/FlowX\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            title
            description
            placeholderEmail
            emailButton
            invalidEmailError
            unknownSubscriptionError
            image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            bgImage {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          relativePath
        }
      }
    }
  `)
  const texts = useComponentTexts(data)
  const newsletter = useNewsletterSubscription("flow-x", texts)

  return (
    <div
      className={`${styles.access} ${variant === "small" ? styles.small : ""}`}
    >
      <div className={styles.bg}>
        <Img
          fluid={texts.bgImage.childImageSharp.fluid}
          style={{ height: "100%", width: "100%" }}
        />
      </div>
      <h3 className={styles.title}>{texts.title}</h3>
      <p className={styles.description}>{texts.description}</p>

      <div
        className={`${styles.separatorText} ${
          styles.separatorText_Margin
        } ${newsletter.status === "error" && styles.error}`}
      >
        {newsletter.status === "error" ? newsletter.error : ""}
      </div>

      <form
        className={`${styles.contentInput} ${newsletter.status === "error" &&
          styles.error}`}
        {...newsletter.formProps}
      >
        <input
          className={styles.emailInput}
          type="text"
          placeholder={texts.placeholderEmail}
          {...newsletter.inputProps}
        />
        <button
          type="submit"
          className={`button ${styles.emailButton}`}
          {...newsletter.buttonProps}
        >
          {texts.emailButton}
        </button>
      </form>
      {variant !== "small" && (
        <div className={styles.image}>
          <Img
            fluid={texts.image.childImageSharp.fluid}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      )}
    </div>
  )
}

export default FlowX
