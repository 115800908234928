import React, { FC, useContext } from "react"
import format from "date-fns/format"
import { getDateFnsDateFormat, getDateFnsLocale } from "src/utils/date-fns"
import CheckIcon from "src/components/Icons/Check"
import { LangContext } from "src/context/lang"
import * as styles from "./styles.module.scss"
import { Props, IconProps } from "./types"
import FeatureChat from "../Icons/FeatureChat"
import FeatureTasks from "../Icons/FeatureTasks"
import FeatureDesign from "../Icons/FeatureDesign"
import Image from "gatsby-image"

const Icon: FC<IconProps> = ({ icon }) => {
  // noinspection JSRedundantSwitchStatement
  switch (icon) {
    case "design":
      return <FeatureDesign className={styles.feature} aria-hidden="true" />
    case "tasks":
      return <FeatureTasks className={styles.feature} aria-hidden="true" />
    case "chat":
      return <FeatureChat className={styles.feature} aria-hidden="true" />
    default:
      return <CheckIcon className={styles.check} aria-hidden="true" />
  }
}

const Update: FC<Props> = ({ date, description, name, sections, image }) => {
  const { lang } = useContext(LangContext)

  return (
    <section className={styles.wrapper}>
      <div className={`content-wrapper`}>
        <h2 className={styles.date}>
          {format(date, getDateFnsDateFormat(lang), {
            locale: getDateFnsLocale(lang),
          })}
        </h2>
        <div className={styles.content}>
          <div className={styles.column}>
            <span className={styles.name}>{name}</span>
            <p className={styles.description}>{description}</p>
            <Image
              className={styles.image}
              fluid={image.childImageSharp.fluid}
              aria-hidden={true}
            />
          </div>
          <div className={styles.column}>
            {sections.map(({ name, elements, icons }) => (
              <div className={styles.section} key={name}>
                <h3 className={styles.sectionTitle}>{name}</h3>
                <ul className={styles.list}>
                  {elements.map(element => (
                    <li
                      className={`${styles.element} ${
                        icons ? styles.elementIcons : ""
                      }`}
                      key={element.text}
                    >
                      <Icon icon={element.icon} />
                      <span className={styles.itemText}>{element.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Update
