import React, { FC } from "react"
import { graphql } from "gatsby"
import { Layout } from "src/components/Layout"
import Update from "src/components/Update"
import { Props } from "./types"
import Jumbotron from "src/components/Jumbotron"
import * as styles from "./styles.module.scss"
import { usePages } from "src/context/pages"
import FlowXBanner from "src/components/Banners/FlowX"
import Logo from "src/images/structured-data-logo.png"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      headerClassname={`white-nav`}
    >
      <Jumbotron title={texts.header} richText={texts.subtitle} />
      <div className={styles.updates}>
        {texts.updates.map(({ date, ...update }: any) => (
          <Update key={update.name} date={new Date(date)} {...update} />
        ))}
        <div className={`content-wrapper`}>
          <FlowXBanner />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subtitle
        updates {
          name
          description
          date
          sections {
            name
            elements {
              text
              icon
            }
            icons
          }
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default Template
