import { useCallback, useState, useRef } from "react"
import { useUnmounted } from "./useUnmounted"
import { isEmailValid } from "src/utils/isEmailValid"
import { navigate } from "gatsby"

type InitialState = {
  status: "initial"
}

type LoadingState = {
  status: "loading"
}

type ErrorState = {
  status: "error"
  error: string
}

type SuccessState = {
  status: "success"
}

type NewsletterSubscriptionState =
  | ErrorState
  | LoadingState
  | InitialState
  | SuccessState

export type NewsletterType = "global" | "newsletter" | "flow-x"

export function useNewsletterSubscription(
  type: NewsletterType,
  texts: {
    invalidEmailError?: string
    unknownSubscriptionError?: string
  },
) {
  const emailRef = useRef<HTMLInputElement>(null)
  const [email, setEmail] = useState("")
  const [state, setState] = useState<NewsletterSubscriptionState>(() => ({
    status: "initial",
  }))

  const unmountedRef = useUnmounted()

  const subscribe = useCallback(
    async (
      type: NewsletterType,
      email = emailRef.current?.value || "",
      navigateOnSuccess = true,
    ) => {
      try {
        if (!isEmailValid(email)) {
          return setState({
            status: "error",
            error: texts.invalidEmailError || "invalidEmailError",
          })
        }

        setState({
          status: "loading",
        })

        const response = await fetch(`/.netlify/functions/newsletter`, {
          method: "POST",
          body: JSON.stringify({
            email,
            type,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })

        const data = await response.json()

        if (unmountedRef.current) return

        if (response.status === 201) {
          if (emailRef.current?.value) {
            emailRef.current.value = ""
          }

          if (navigateOnSuccess) {
            navigate("/newsletter/success", { state: { from: type } })
          }
        } else {
          setState({
            status: "error",
            error:
              data?.error ||
              texts.unknownSubscriptionError ||
              "unknownSubscriptionError",
          })
        }
      } catch (error) {
        if (unmountedRef.current) return

        setState({
          status: "error",
          error:
            error.message ||
            texts.unknownSubscriptionError ||
            "unknownSubscriptionError",
        })
      }
    },
    [],
  )

  const reset = useCallback(() => {
    setState({
      status: "initial",
    })

    setEmail("")
  }, [])

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      subscribe(type)
    },
    [subscribe, type],
  )

  return {
    ...state,
    email,
    subscribe,
    reset,
    onSubmit,
    formProps: {
      onSubmit,
    },
    inputProps: {
      type: "text",
      disabled: state.status === "loading",
      ref: emailRef,
      onFocus: () => reset(),
    },
    buttonProps: {
      disabled: state.status === "loading",
    },
  }
}
